import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { errorHandler } from "../../../redux/slices/internalServerError";
import TitleHeader from "../../../components/CustomComponents/title";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import LocationDetails from "./ListingLocationDetails/index";
import useAuth from "../../../hooks/useAuth";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";

import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  MenuItem,
  Tooltip,
  tooltipClasses,
  TextField,
  Autocomplete,
  IconButton,
  Dialog,
  Menu,
  Checkbox,
  createFilterOptions,
} from "@mui/material";
import styled from "styled-components/macro";
import { ReactComponent as AddGroup } from "../../../assets/images/AddGroup.svg";

import { ReactComponent as More } from "../../../assets/images/MoreIcon.svg";
import { ReactComponent as Google } from "../../../assets/images/Button.svg";
import { ReactComponent as Vector } from "../../../assets/images/Facebook.svg";
import { ReactComponent as Apple } from "../../../assets/images/AppleIcon.svg";
import { ReactComponent as Bing } from "../../../assets/images/Bing.svg";
import InternalServerErrorImg from "../../../assets/images/serverErrorImage.svg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import Loader from "../../../components/Loaders/Loader";
import BouncingDotsLoader from "../../../components/Loaders/bounsingLoader";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { toast } from "react-toastify";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Googleicon from "../../../assets/Icons/Group.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RatingContainer from "./ListingResults/Components/rating";
import GoogleError from "../../../components/CustomComponents/Errors/NotConnected";
import LocationRequestedState from "../../../components/CustomComponents/Errors/LocationRequested";
import LocationNotFound from "../../../components/CustomComponents/Errors/NoLocationFound";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import Delete from "../../../components/Models/DeleteModal/subscriptionModel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "./Styles/style";
import Switch from "@mui/material/Switch";
import SyncModel from "../../../components/Models/DeleteModal/syncModel";
import GoogleConnectModal from "../../../components/Models/DeleteModal/GoogleConnectModal";
import { LoadingButton } from "@mui/lab";
import FilterModalListings from "../../../components/Models/BulkUpdateFilterModel";
import ListingBulkUpdateModel from "./ListingBulkUpdate/Modal/index";
import ListingChannel from "./ListingChannels/index";
import axios from "axios";
import InternalServerErrorModal from "../serverErrorModal";
import { countriesData } from "../../../assets/countriesList";
import AddIcon from "@mui/icons-material/Add";
import Iframe from "../../../components/Models/IframeModal/Iframe";
import { languageOptions } from "../../../assets/defaultLanguage";
import FilterModal from "./BulkUpdateFIlterModel";
import useDateFormat from "../../../hooks/useDateFormat";
import { getSelectedCustomer } from "../../../utils/LocalStorageHelpers";
import { LanguageChange } from "../../../utils/LocalStorageHelpers";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

function Listings() {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  const { signOut, permissionsAcess } = useAuth();
  const [uberAllLoading, setUberAllLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jobLoading, setjobLoading] = useState(false);

  const [allLocations, setAllLocations] = useState([]);
  const [location, setLocation] = useState(null);
  const [displayGoogleError, setDisplayGoogleError] = useState(false);

  const [open, setOpen] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);

  const languageData = localStorage.getItem("i18nextLng");

  let userLang = "";
  let selectedLanguage = "";

  selectedLanguage = languageOptions[userLang];
  const { user } = useAuth();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [locationDetailsData, setLocationDetailsData] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const { i18n } = useTranslation;

  const stateData = JSON.parse(localStorage.getItem("user"));

  const [changeLoc, setChangeLoc] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);
  const themeData = localStorage.getItem("theme");
  const [autoSync, setAutoSync] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [openAcceptModel, setOpenAcceptModel] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [callApi, setCallApi] = useState(false);

  const [singleLocation, setSingleLocation] = useState(null);
  const [allCompanyLocations, setAllCompayLocations] = useState([]);
  const [channelData, setChannelData] = useState([]);
  const [singleLocationLoading, setSingleLocationLoading] = useState(false);
  const [channelsLoading, setChannelsLoading] = useState(false);
  const [googleConnect, setGoogleConnect] = useState(null);
  const [facebookConnect, setFacebookConnect] = useState(null);
  const [cancelTokenSources, setCancelTokenSources] = useState([]);
  const [churnState, setChurnState] = useState(null);
  const [lodgingCategories, setLodgingCategories] = useState([]);
  const [selectedLocationsBulkUpdate, setSelectedLocationsBulkUpdate] =
    useState([]);
  const [openGoogleModel, setOpenGoogleModel] = useState(false);
  const [listingPersmissions, setListingPermission] = useState(null);
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);

  const [locationEditPermission, setLocationEditPermission] = useState(false);
  const [locationAddPermission, setLocationAddPermission] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countriesForModel, setCountriesForModel] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openIframeModel, setOpenIframeModel] = useState(false);
  const filter = createFilterOptions();

  const [locationDeletePermission, setLocationDeletePermission] =
    useState(false);
  const [locationViewPermission, setLocationViewPermission] = useState(false);
  const [centerData, setCenterData] = useState({ lat: "", lng: "" });
  const [displayMapError, setDisplayMapError] = useState(false);
  const [profileLink, setProfileLink] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { internalError } = useSelector((state) => state.serverError);
  const [locationRequestError, setLocationRequestError] = useState(false);
  const [defaultValueLocation, setDefaultvalueLocation] = useState(null);
  const [allGroups, setAllGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const [openEditBulkModel, setOpenBulkEditModel] = useState(false);
  const [openFilterBulkModel, setOpenFilterBulkModel] = useState(false);
  const { formatDate } = useDateFormat();
  const [processedLocations, setProcessedLocations] = useState(null);

  useEffect(() => {
    if (
      languageData !== undefined &&
      languageData !== undefined &&
      stateData !== undefined &&
      stateData !== null
    ) {
      getSelectedCustomer(handleSignOut);
      if (languageData !== "en" || stateData?.language === null) {
        LanguageChange(setCallApi, closeMenu);
      } else {
        setCallApi(true);
      }
      getAllLocations();
      getAllGroups();
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, []);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/locationListing"
      );
      setListingPermission(filteredPermissions);

      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });

      let filteredLocationPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/location"
      );

      filteredLocationPermissions?.map((item) => {
        if (
          item?.permissionName === "create" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationAddPermission(true);
        }
        if (
          item?.permissionName === "edit" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationEditPermission(true);
        }
        if (
          item?.permissionName === "delete" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationDeletePermission(true);
        }
        if (
          item?.permissionName === "read" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationViewPermission(true);
        }
      });
    }
    setSelectedIndex(0);
  }, []);

  useEffect(() => {
    if (
      location?.socialLink?.length > 0 &&
      location?.enableUberAll === "approved" &&
      locationDetailsData?.result?.uberall?.locationId !== null &&
      selectedTab === 1
    ) {
      getChannelById(locationDetailsData);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (singleLocation !== null) {
      if (singleLocation?.socialLink?.length > 0) {
        const googleLink = singleLocation?.socialLink?.find(
          (element) => element?.type === "google"
        );
        if (googleLink !== undefined) {
          setGoogleConnect(googleLink);
        } else {
          setGoogleConnect(null);
        }
        const facebookLink = singleLocation?.socialLink?.find(
          (element) => element?.type === "facebook"
        );
        if (facebookLink !== undefined) {
          setFacebookConnect(facebookLink);
        } else {
          setFacebookConnect(null);
        }
      } else {
        setFacebookConnect(null);
        setGoogleConnect(null);
      }
    }
  }, [singleLocation]);
  useEffect(() => {
    if (location !== null) {
      getLocationById(location?.id);
    }
  }, [location]);

  useEffect(() => {
    if (
      location !== null &&
      location?.socialLink?.length > 0 &&
      selectedTab === 0
    ) {
      getLocationDetails(location);
      getLodgingCategories();
      //  getLocationDetailsListing(location);
    }
  }, [selectedTab, location, callApi]);

  useEffect(() => {
    if (themeData === null) {
      localStorage.setItem("theme", "light");
    }
  }, []);
  function allElementsAreZero(arr) {
    return arr.every((element) => element === 0);
  }
  useEffect(() => {
    if (locationDetailsData !== null) {
      if (locationDetailsData?.result?.google?.latlng !== null) {
        setDisplayMapError(false);
        setCenterData({
          lat: locationDetailsData?.result?.google?.latlng?.latitude,
          lng: locationDetailsData?.result?.google?.latlng?.longitude,
        });
        setDisplayMapError(false);
      } else if (
        locationDetailsData?.result?.latlng !== null &&
        locationDetailsData?.result?.latlng?.coordinates?.length > 0 &&
        allElementsAreZero(locationDetailsData?.result?.latlng?.coordinates) ==
          false
      ) {
        setCenterData({
          lat: locationDetailsData?.result?.latlng?.coordinates[1],
          lng: locationDetailsData?.result?.latlng?.coordinates[0],
        });
        setDisplayMapError(false);
      } else {
        setDisplayMapError(true);
        setCenterData({
          lat: null,
          lng: null,
        });
      }
    }
  }, [locationDetailsData]);

  useEffect(() => {
    if (
      locationDetailsData?.result?.lastSync !== null
      // locationDetailsData?.result?.google?.  t !== null
    ) {
      if (
        location !== null &&
        location?.socialLink?.length > 0 /* &&
        (location?.socialLink[0]?.statusOfGoogleInsight === "Done" ||
          location?.socialLink[0]?.statusOfGoogleInsight === "pending") */
      ) {
        const dt1 = new Date();
        const dt2 = new Date(
          locationDetailsData?.result?.lastSync !== null &&
            locationDetailsData?.result?.lastSync
        );
        var hours = Math.abs(dt1 - dt2);
        var minutes = Math.floor(hours / 1000 / 60);

        function diff_hours(dt2, dt1) {
          var diff = (dt2.getTime() - dt1.getTime()) / 1000;
          diff /= 60 * 60;

          return Math.abs(diff);
        }

        if (diff_hours(dt1, dt2) >= 1) {
          setDisableButton(false);
        } else {
          setDisableButton(true);
        }
      }
    } else {
      setDisableButton(false);
    }
  }, [locationDetailsData]);

  useEffect(() => {
    if (allLocations.length > 0) {
      setChangeLoc(true);
    }
  }, [allLocations]);

  const addCancelTokenSource = () => {
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSources((prevSources) => [
      ...prevSources,
      newCancelTokenSource,
    ]);
    return newCancelTokenSource;
  };

  const removeCancelTokenSource = (source) => {
    setCancelTokenSources((prevSources) =>
      prevSources.filter((cancelTokenSource) => cancelTokenSource !== source)
    );
  };

  const cancelAllRequests = () => {
    cancelTokenSources.forEach((cancelTokenSource) => {
      cancelTokenSource.cancel("New request is made");
    });
  };

  const getLocationById = async (locationId) => {
    const newCancelTokenSource = addCancelTokenSource();
    try {
      setSingleLocationLoading(true);
      const res = await api.get(`/location/${locationId}`, {
        cancelToken: newCancelTokenSource.token,
      });
      if (res.status === 200) {
        setSingleLocation(res.data.data);
        setSingleLocationLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setSingleLocation(null);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
      if (axios.isCancel(error)) {
      } else {
        setSingleLocationLoading(false);
      }
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };
  const getChannelById = async (locationDetailId) => {
    try {
      setChannelsLoading(true);
      const res = await api.get(
        `/locationListing/getUberAllListings/${
          locationDetailId !== null
            ? locationDetailId?.result?.uberall?.locationId
            : ""
        }`
      );
      if (res.status === 200) {
        setChannelData(res.data.data);
        // setChannelData(null);
        setChannelsLoading(false);
      }
    } catch (error) {
      setChannelsLoading(false);
      setChannelData(null);
    }
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const removeDuplicates = (arr, prop) => {
    return arr.filter(
      (obj, index, self) =>
        index === self.findIndex((el) => el[prop] === obj[prop])
    );
  };

  const getAllGroups = async () => {
    try {
      const res = await api.get(`groups?pageNumber=1&limit=1000`);
      if (res.status === 200) {
        setAllGroups(res?.data?.data);
      }
    } catch (error) {
      setAllGroups(null);
    }
  };

  const getAllLocations = async (groupId) => {
    setLocationLoading(true);
    try {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
          isListingPage: true,
          groupId: groupId !== undefined ? groupId : "",
        },
      });
      if (res.status === 200) {
        setLocationLoading(false);
        //  setAllLocations(res?.data?.data);
        const defaultLocation = res?.data?.data?.results.find(
          (item) => item?.defaultLocation !== null
        );

        setDefaultvalueLocation(defaultLocation);
        let countriesArr = [];
        let count = 0;
        if (res?.data?.data?.results?.length > 0) {
          res?.data?.data?.results?.forEach((item, index) => {
            countriesData?.forEach((itemTwo) => {
              if (
                item?.countryCode !== null &&
                item?.countryCode !== "" &&
                item?.countryCode !== undefined &&
                item?.countryCode === itemTwo?.code
              ) {
                countriesArr?.push(itemTwo);
              }
            });
          });
          if (countriesArr?.length > 0) {
            const uniqueCountries = removeDuplicates(countriesArr, "name");

            const countryWiseLocations = getCountryWiseLocations(
              countriesData,
              res?.data?.data?.results
            );
            setCountries(countryWiseLocations);
            setCountriesForModel(countryWiseLocations);
          } else {
            setCountries([]);
          }

          let data = res?.data?.data?.results;
          const index = data.findIndex((object) => {
            return object.defaultLocation !== null;
          });
          if (index > 0) {
            data.unshift(data.splice(index, 1)[0]);
          }
          setAllCompayLocations(data);
          setAllLocations(data);
          if (defaultLocation !== undefined) {
            setLocation(defaultLocation);
          } else {
            const earliestSubscription = res?.data?.data?.results?.reduce(
              (earliest, current) => {
                return new Date(current.subscriptionDate) <
                  new Date(earliest.subscriptionDate)
                  ? current
                  : earliest;
              }
            );

            setLocation(earliestSubscription);
          }
        } else {
          setAllLocations(res?.data?.data?.results);
          setAllCompayLocations(res?.data?.data?.results);
        }

        /*    if (
          defaultLocation !== undefined &&
          defaultLocation?.socialLink?.length===0
        ) {
          setDisplayGoogleError(true);
        } else {
          setDisplayGoogleError(false);
        } */
        if (defaultLocation !== undefined) {
          if (defaultLocation?.status === "requested") {
            setLocationRequestError(true);
          } else {
            setLocationRequestError(false);
            if (defaultLocation?.socialLink?.length === 0) {
              setDisplayGoogleError(true);
            } else {
              setDisplayGoogleError(false);
            }
          }
        } else {
          const earliestSubscription = res?.data?.data?.results?.reduce(
            (earliest, current) => {
              return new Date(current.subscriptionDate) <
                new Date(earliest.subscriptionDate)
                ? current
                : earliest;
            }
          );
          if (earliestSubscription?.status === "requested") {
            setLocationRequestError(true);
          } else {
            setLocationRequestError(false);
            if (earliestSubscription?.socialLink?.length === 0) {
              setDisplayGoogleError(true);
            } else {
              setDisplayGoogleError(false);
            }
          }
        }

        /*    if (defaultLocation !== undefined) {
          if (defaultLocation?.soconnect_connectivity_status===true) {
            setDisplayLocationTab(false);
          } else {
            setDisplayLocationTab(true);
          }
        } else {
          if (
            res?.data?.data?.results[0]?.soconnect_connectivity_status===true
          ) {
            setDisplayLocationTab(false);
          } else {
            setDisplayLocationTab(true);
          }
        } */
        /*  if (
          defaultLocation !== undefined &&
          defaultLocation?.soconnect_connectivity_status===true
        ) {
          setDisplayLocationTab(false);
        } else {
          setDisplayLocationTab(true);
        } */

        setLocationLoading(false);
      }
    } catch (error) {
      setLocationLoading(false);
      setLocation(null);

      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const getFilteredLocationsByCountries = (value) => {
    let filteredLocations = [];
    if (value?.length > 0) {
      value?.forEach((itemOne) => {
        allCompanyLocations?.forEach((location) => {
          if (
            location?.countryCode !== null &&
            location?.countryCode !== "" &&
            location?.countryCode === itemOne?.countryCode
          ) {
            filteredLocations?.push(location);
          }
        });
      });
      if (filteredLocations?.length > 0) {
        setAllLocations(filteredLocations);
        setLocation(filteredLocations[0]);
      } else {
        setAllLocations(allCompanyLocations);
        setLocation(allCompanyLocations[0]);
      }
    } else {
      setAllLocations(allCompanyLocations);
      setLocation(allCompanyLocations[0]);
    }
  };

  /*  const LanguageChange = async () => {
    let userLng = "";
    const defaultLanguage = Object.keys(languageOptions).find((item) => {
      userLng = languageOptions[`${languageData}`]?.name;
    });

    try {
      const res = await api.patch(`/user/${user.id}`, {
        firstName: stateData.firstName,
        lastName: stateData.lastName,
        email: stateData.email,
        language: userLng,
        role: stateData.role,
      });

      if (res.status === 200) {
        setCallApi(true);
        closeMenu();
        localStorage.setItem("user", JSON.stringify(res.data.data));

        const UpdatedLanguage = Object.keys(languageOptions).find(
          (item) =>
            languageOptions[`${item}`]?.name === res?.data?.data?.language
        );
        //     toast.success(t("Language_updated_successfully"));

        localStorage.setItem("i18nextLng", UpdatedLanguage);
      }
    } catch (error) {}
  }; */
  const handleChangeSwitch = async (e) => {
    if (e.target.checked) {
      setAutoSync(true);
    } else {
      setAutoSync(false);
    }

    try {
      setLoadingLocation(true);
      const res = await api.patch(
        `/locationListing/update-autoSync/${location?.id}`,
        {
          autoSync: e.target.checked,
        }
      );

      if (res.status === 200) {
        setLoadingLocation(false);
        //  toast.success("Updated Successfully");
        getLocationDetails(location);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoadingLocation(false);
    }
  };
  const handleNavigationToGroups = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 3, page: 0 },
    });
  };
  const handleChangeGroups = (value) => {
    // setLocationDetailsData(null);
    setSelectedGroup(value);
    setLocation(null);
    setSingleLocation(null);
    getAllLocations(value?.id);
  };
  const handleChangeLocation = async (loc, ind) => {
    // setLocationDetailsData(null);
    setSelectedIndex(ind);
    if (loc !== null) {
      setLocation(loc);
      let googleSocialLink = loc?.socialLink?.find(
        (ele) => ele?.type === "google"
      );

      setProfileLink(null);

      getLocationById(loc?.id);
      setSelectedTab(0);
      if (loc?.status === "requested") {
        setLocationRequestError(true);
      } else {
        setLocationRequestError(false);
        if (googleSocialLink === undefined || loc?.socialLink?.length === 0) {
          setDisplayGoogleError(true);
          setLocation(loc);
        } else if (
          loc?.socialLink?.length > 0 &&
          // displayLocationTab===true &&
          selectedTab === 0
        ) {
          setDisplayGoogleError(false);

          setLocation(loc);
        }
      }
    }
  };
  const handleViewLocation = (selectedLocation) => {
    setLocation(selectedLocation);
  };

  const handleSendUberAllRequest = async () => {
    setUberAllLoading(true);
    let locationIds = [];
    if (selectedLocationsBulkUpdate?.length > 0) {
      selectedLocationsBulkUpdate.map((item) => {
        locationIds.push(item.id);
      });
    }
    try {
      const res = await api.patch(`/location/bulkEnableUberAll`, {
        locationIds: locationIds,
        enableUberAll: "requested",
      });

      if (res.status === 200) {
        setUberAllLoading(false);
        getLocationById(location?.id);
        handleCloseConfirmModel();
        handleCloseFilterModel();
        // setSingleLocation(res?.data?.data);
        toast.success(res?.data?.message);
      }
    } catch (error) {
      setUberAllLoading(false);
    }
  };
  function kFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (Math.abs(Number(num)) / 1.0e6).toFixed(2) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return Math.sign(num) * Math.abs(num); // if value < 1000, nothing to do
    }
  }
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleNavigation = (selectedLocation) => {
    let isLocation = false;
    selectedLocation?.locationSubscription?.forEach((location, index) => {
      if (
        location?.subscription?.name === "listing" &&
        location?.status === "active"
      ) {
        isLocation = true;
      }
    });
    if (isLocation) {
      navigate("/user/view_location/" + selectedLocation?.id, {
        state: { row: selectedLocation },
      });
    } else {
      // toast.warn("Please Subscribe listing");
      setOpen(true);
    }
  };

  const handleSubscriptionNavigation = () => {
    navigate(`/user/edit_location?state=${location?.id}/`, {
      state: {
        showProduct: true,
        displayOn: false,
      },
    });
  };

  async function fetchURL() {
    try {
      const res = await api.get(`googleConnect/auth-url`);
      if (res.data?.status === true) {
        window.location.href = res?.data.data /* + "&state=" + id */;
      }
    } catch (error) {}
  }
  const handleConnectWithGoogle = () => {
    fetchURL();
  };
  const handleOpenGoogleModel = () => {
    setOpenGoogleModel(true);
  };
  const handleCloseGoogleModel = () => {
    setOpenGoogleModel(false);
  };
  const getLocationDetails = async (locationData) => {
    //  setTimesArr([]);
    setLoadingLocation(true);
    try {
      const response = await api.get(`locationListing/${locationData.id}`);

      if (response.status === 200) {
        setAutoSync(response?.data?.data?.result?.autoSync);
        setLoadingLocation(false);
        setLocationDetailsData(response?.data?.data);
        setProfileLink(response?.data?.data?.result?.google?.metadata?.mapsUri);
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      setLoadingLocation(false);
      setLocationDetailsData(null);
      setProfileLink(null);

      handleSessionOut(
        error?.response?.status,
        error?.response?.data?.message,
        handleSignOut
      );
      if (error?.response?.status === 500) {
        dispatch(errorHandler(true));
      }
    }
  };
  const getLodgingCategories = async () => {
    //  setTimesArr([]);

    try {
      const response = await api.get(`lodging/categories`);

      if (response.status === 200) {
        setLodgingCategories(response?.data?.data);
      }
    } catch (error) {}
  };
  const getLocationDetailsListing = async (locationData) => {
    try {
      //  setLoadingLocation(true);
      const response = await api.get(`locationListing/${locationData.id}`);
      if (response.status === 200) {
        setAutoSync(response?.data?.data?.result?.autoSync);
        //  setLoadingLocation(false);
        setLocationDetailsData(response?.data?.data);
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      //  setLoadingLocation(false);
    }
  };
  const handleChangeCountries = (value) => {
    if (value.find((option) => option.id === 0)) {
      // Check if all options are selected
      if (countries?.length === selectedCountries?.length) {
        setSelectedCountries([]);
        setSelectAll(false);
      } else {
        setSelectAll(true);
        setSelectedCountries(countries);
        //  setLocationRequired(false);
        setAllLocations(allCompanyLocations);
        setLocation(allCompanyLocations[0]);
      }
    } else {
      setSelectedCountries(value);
      getFilteredLocationsByCountries(value);

      if (value?.length === countries?.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  };

  const getCountry = (code) => {
    const locationCountry = countriesData?.find((item) => item?.code === code);
    return locationCountry?.name;
  };

  const handleAcceptAccount = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `locationListing/sync-with-google/${location?.id}`
      );
      if (res.status === 200) {
        setIsLoading(false);
        handleCloseAcceptModel();
        // toast.success(res?.data?.message);
        toast.success(t("Successfully_synced_with_google"));
        //  navigate(`/admin/accounts/${id}`);
        getLocationDetails(location);
        getLocationById(location?.id);
        // getLocationDetailsListing(location);
      }
    } catch (error) {
      setIsLoading(false);

      toast.error(error?.response?.data?.message);
      handleCloseAcceptModel();
    }
  };
  const handleCronJob = async () => {
    setjobLoading(true);
    try {
      const res = await api.get(
        `locationListing/cron-job-google-for-single-location/${location?.id}`
      );
      if (res.status === 200) {
        setjobLoading(false);

        toast.success(res?.data?.message);
        getLocationDetails(location);
        getLocationById(location?.id);
        //  navigate(`/admin/accounts/${id}`);
      }
    } catch (error) {
      setjobLoading(false);
      // getLocationDetails(location);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleOpenAcceptModel = () => {
    setOpenAcceptModel(true);
  };
  const handleCloseAcceptModel = () => {
    setOpenAcceptModel(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
    // setSelectedRow(null);
  };
  const handleReloadPage = () => {
    window.location.reload();
  };
  const handleAddLocation = () => {
    navigate("/user/add-location");
  };

  const handleOpenTemplateMenu = () => {
    setOpenIframeModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenIframeModel(false);
  };

  const handleOpenBulkEditModel = () => {
    setOpenBulkEditModel(true);
  };
  const handleCloseBulkEditModel = () => {
    setOpenBulkEditModel(false);
    getLocationById(location?.id);
    getLocationDetails(location);
  };

  const handleOpenFilterModel = () => {
    setOpenFilterBulkModel(true);
  };
  const handleCloseFilterModel = () => {
    setOpenFilterBulkModel(false);
  };
  const getCountryWiseLocations = (countriesData, locationsArray) => {
    return countriesData
      .map((country) => {
        const locations = locationsArray
          .filter(
            (location) =>
              location.countryCode === country.code ||
              location.country === country.name
          )
          .map((location) => location);
        return {
          countryName: country.name,
          countryCode: country.code,
          locationsArray: locations,
          disable: !locations.some((ele) => ele.socialLink.length > 0),
        };
      })
      .filter((country) => country.locationsArray.length > 0);
  };

  const handleOpenConfirmModel = () => {
    setOpenConfirmModel(true);
  };
  const handleCloseConfirmModel = () => {
    setOpenConfirmModel(false);
    getLocationById(location?.id);
    getLocationDetails(location);
  };
  return (
    <>
      {openIframeModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openIframeModel}
          onClose={handleCloseTemplateMenu}
        >
          <Iframe
            title={t("Listings_Tutorial")}
            onCancel={handleCloseTemplateMenu}
            iframe={
              <iframe
                title="iframe"
                loading="lazy"
                src="https://ask.obenan.com/f4tkmskma"
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="1200px"
                height="500px"
                onLoad={() => {}}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              />
            }
          />
        </Dialog>
      )}
      {openAcceptModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={handleOpenAcceptModel}
          onClose={handleCloseAcceptModel}
          aria-labelledby="responsive-dialog-title"
        >
          <SyncModel
            title={t("Sync_Latest_Listing")}
            description={t("Sync_Listing_subhead")}
            onConfirm={() => handleAcceptAccount()}
            onCancel={handleCloseAcceptModel}
            loading={isLoading}
          />
        </Dialog>
      )}
      {openGoogleModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openGoogleModel}
          onClose={handleCloseGoogleModel}
          aria-labelledby="responsive-dialog-title"
        >
          <GoogleConnectModal
            title={t("google_connect_title")}
            description={t("google_connect_description")}
            onConfirm={handleConnectWithGoogle}
            onCancel={handleCloseGoogleModel}
            loading={isLoading}
          />
        </Dialog>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Listing_Subscription")}
          description={t("Listing_subscribtion_subtext")}
          onConfirm={() => handleSubscriptionNavigation()}
          onCancel={handleClose}
        />
      </Dialog>

      <>
        <TitleHeader
          title={t("View_Listings")}
          extraButton={selectedTab === 0 ? true : false}
          extraButtonName={t("Bulk_update")}
          handleDefaultTemplate={handleOpenFilterModel}
          addPermission={addPermission}
          viewPermission={viewPermission}
          // IframeButton={true}
          // IframeButtonName={
          //   <Tooltip title={t("Help")}>
          //     <img src={QuestionMark} />
          //   </Tooltip>
          // }
          // handleDefaultIframe={handleOpenTemplateMenu}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Box className={classes.sideContainer}>
              <Card variant="outlined" className={classes.locationListCard}>
                {countries?.length > 0 && (
                  <>
                    <Typography className={classes.fieldhead}>
                      {t("Country")}
                    </Typography>

                    <Autocomplete
                      multiple
                      disablePortal
                      id="combo-box-demo"
                      options={countries?.length > 0 ? countries : []}
                      getOptionLabel={(option) => option?.countryName}
                      className="Autocomplete-field"
                      value={selectedCountries}
                      onChange={(event, value) => {
                        // Check if 'All' option is clicked
                        handleChangeCountries(value);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.countryName === value.countryName
                      }
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (countries?.length > 0) {
                          return [
                            { id: 0, countryName: "Select All" },
                            ...filtered,
                          ];
                        } else {
                          return [];
                        }
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected || selectAll}
                          />

                          <Typography sx={{ textTransform: "capitalize" }}>
                            {" "}
                            {option?.countryName}{" "}
                          </Typography>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          placeholder={t("Filter_locations_By_country")}
                          {...params}
                          required
                          name="language"
                          sx={{ padding: "8px" }}
                        />
                      )}
                    ></Autocomplete>
                  </>
                )}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography className={classes.fieldhead}>
                    {t("groups_label")}
                  </Typography>

                  {locationViewPermission && locationAddPermission ? (
                    <BootstrapTooltip title={t("manage_groups")}>
                      <IconButton
                        onClick={() => handleNavigationToGroups()}
                        sx={{ paddingTop: "13px", cursor: "pointer" }}
                      >
                        <AddGroup />
                      </IconButton>
                    </BootstrapTooltip>
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span style={{ cursor: "pointer" }}>
                        <IconButton
                          disabled
                          onClick={() => handleNavigationToGroups()}
                          sx={{ paddingTop: "13px", cursor: "pointer" }}
                        >
                          <AddGroup />
                        </IconButton>
                      </span>
                    </BootstrapTooltip>
                  )}
                </Box>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allGroups !== null && allGroups?.rows?.length > 0
                      ? allGroups?.rows
                      : []
                  }
                  getOptionLabel={(option) =>
                    option?.name ? option?.name : ""
                  }
                  className="Autocomplete-field"
                  value={selectedGroup}
                  onChange={(e, value) => {
                    handleChangeGroups(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("filter_locations_groups")}
                      {...params}
                      required
                      name="language"
                      sx={{ padding: "0px 8px 8px 8px" }}
                    />
                  )}
                ></Autocomplete>
                <Typography className={classes.fieldhead}>
                  {t("Account")}
                </Typography>
                <Autocomplete
                  // multiple
                  disablePortal
                  id="combo-box-demo"
                  options={allLocations?.length > 0 ? allLocations : []}
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        " " +
                        "(" +
                        (option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? option?.addressLine1 + "," + " "
                          : "") +
                        (option?.city ? option?.city : "") +
                        ")"
                      : ""
                  }
                  className="Autocomplete-field"
                  value={location}
                  onChange={(e, value) => {
                    handleChangeLocation(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Filter_locations")}
                      {...params}
                      required
                      name="language"
                      sx={{ marginBottom: "8px", padding: "8px" }}
                    />
                  )}
                ></Autocomplete>
                <Divider variant="middle" />
                {locationLoading ? (
                  <Grid container className={classes.loaderContainer1}>
                    <BouncingDotsLoader />
                  </Grid>
                ) : (
                  <Box className={classes.cardbox}>
                    {allLocations?.length > 0 ? (
                      allLocations?.map((item, index) => (
                        <Box
                          key={index}
                          className={classes.cardcontent}
                          sx={{
                            bgcolor:
                              location !== null && location?.id === item?.id
                                ? " #E0F7FF "
                                : " #FFFF",
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={11}
                              md={11}
                              sm={11}
                              lg={11}
                              onClick={() => {
                                handleChangeLocation(item, index);
                              }}
                            >
                              <Typography className={classes.titleNameLocation}>
                                {item?.internalName}
                              </Typography>
                              {/*  <Typography className={classes.titleNameLocation}>
                                {getCountry(item?.countryCode)}
                              </Typography> */}

                              <Typography className={classes.locationAddress}>
                                {item?.addressLine1}
                              </Typography>
                              {singleLocation !== null &&
                                singleLocation?.id === item?.id &&
                                locationDetailsData !== null &&
                                loadingLocation === false &&
                                selectedIndex !== null &&
                                selectedIndex === index &&
                                locationDetailsData?.result?.google?.openInfo
                                  ?.status === "CLOSED_PERMANENTLY" && (
                                  <Typography
                                    className={
                                      classes.locationPermanentClosedText
                                    }
                                  >
                                    {t("Permanently_Closed")}
                                  </Typography>
                                )}
                              <Box>
                                <Grid
                                  item
                                  xs={6}
                                  md={6}
                                  lg={6}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {item?.googleSearchInsight !== null && (
                                    <CustomWidthTooltip
                                      title={t("views_on_google")}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <RemoveRedEyeIcon
                                          fontSize="small"
                                          className={classes.iconStyle}
                                        />
                                        <Typography
                                          className={classes.subheadName}
                                        >
                                          {kFormatter(
                                            item?.googleSearchInsight
                                          )}
                                        </Typography>
                                      </Box>
                                    </CustomWidthTooltip>
                                  )}
                                  {item?.googleMapInsight !== null && (
                                    <CustomWidthTooltip
                                      title={t("Check_location_on")}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <LocationOnIcon
                                          fontSize="small"
                                          className={classes.iconStyle}
                                        />

                                        <Typography
                                          className={classes.subheadName}
                                        >
                                          {kFormatter(item?.googleMapInsight)}
                                        </Typography>
                                      </Box>
                                    </CustomWidthTooltip>
                                  )}
                                  {/*   {item?.socialLink?.length > 0 &&
                              item?.socialLink[0]?.validToken===false && (
                                <CustomWidthTooltip title={t("token_expired")}>
                                  <ErrorOutlineIcon
                                    fontSize="small"
                                    sx={{ color: "#FF1616", marginLeft: "5px" }}
                                  />
                                </CustomWidthTooltip>
                              )} */}
                                </Grid>
                              </Box>
                            </Grid>

                            <CustomWidthTooltip title={t("View_Location")}>
                              <Grid
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                sx={{ textAlign: "end" }}
                                onClick={() => handleViewLocation(item)}
                              >
                                <KeyboardArrowRightIcon
                                  className={classes.iconStyle}
                                />
                              </Grid>
                            </CustomWidthTooltip>
                          </Grid>
                        </Box>
                      ))
                    ) : (
                      <Card className={classes.cardcontent}>
                        <Grid
                          container
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Typography className={classes.subheadName}>
                            {t("No_Location_Found")}
                          </Typography>
                        </Grid>
                      </Card>
                    )}
                  </Box>
                )}
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card variant="outlined" className={classes.ratingCardContainer}>
                <Grid
                  sx={{
                    height: "100%",
                    paddingTop: "10px",
                    // paddingBottom: "8px",
                  }}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <RatingContainer
                    allLocations={allLocations}
                    location={location}
                    handleNavigation={handleNavigation}
                    singleLocationLoading={singleLocationLoading}
                    profileLink={profileLink}
                  />

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={/* displayLocationTab===true ? 9 : */ 9}
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent:
                        /* displayLocationTab===true ? "flex-end" : */ "flex-end",
                      //  paddingRight: "6px",
                    }}
                  >
                    <Box sx={{ display: "flex", marginTop: "10px" }}>
                      <Google />
                      <Vector />
                      <Apple />
                      <Bing />
                      <More />
                    </Box>

                    {!isMobile &&
                      singleLocation !== null &&
                      allLocations?.length > 0 && (
                        <Divider
                          orientation="vertical"
                          sx={{ marginX: "16px" }}
                          flexItem
                        />
                      )}

                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#1B2430",
                        //  marginTop: "8px",
                        marginLeft: "12px",
                      }}
                    >
                      {t("Auto-sync-des")}

                      {editPermission && viewPermission ? (
                        locationRequestError ? (
                          <BootstrapTooltip
                            title={`${location?.name} ${t(
                              "Requeste_state_subheading"
                            )}`}
                          >
                            <span>
                              <Switch
                                className={
                                  autoSync
                                    ? classes.toggleUnChecked
                                    : classes.toggleChecked
                                }
                                checked={autoSync}
                                disabled={locationRequestError}
                                onChange={(e) => handleChangeSwitch(e)}
                              />
                            </span>
                          </BootstrapTooltip>
                        ) : (
                          <Switch
                            className={
                              autoSync
                                ? classes.toggleUnChecked
                                : classes.toggleChecked
                            }
                            checked={autoSync}
                            onChange={(e) => handleChangeSwitch(e)}
                          />
                        )
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <Switch
                              className={classes.toggleDisabled}
                              disabled={true}
                              checked={autoSync}
                              onChange={(e) => handleChangeSwitch(e)}
                            />
                          </span>
                        </BootstrapTooltip>
                      )}
                    </Typography>

                    {!isMobile &&
                      singleLocation !== null &&
                      allLocations?.length > 0 && (
                        <Divider
                          orientation="vertical"
                          sx={{ marginX: "16px" }}
                          flexItem
                        />
                      )}
                    {editPermission && viewPermission ? (
                      <BootstrapTooltip
                        title={
                          locationRequestError
                            ? `${location?.name} ${t(
                                "Requeste_state_subheading"
                              )}`
                            : locationDetailsData !== null &&
                              locationDetailsData?.result?.autoSync === false
                            ? t("auto_sync_tooltip")
                            : disableButton === true
                            ? t("sync_tooltip")
                            : t("Sync_Now")
                        }
                      >
                        <span>
                          <LoadingButton
                            loading={jobLoading}
                            onClick={handleCronJob}
                            disabled={
                              locationRequestError ||
                              disableButton ||
                              (locationDetailsData !== null &&
                                locationDetailsData?.result?.autoSync === false)
                            }
                            variant="contained"
                            color="primary"
                            className={classes.jobLoadingButton}
                          >
                            {t("Sync_Now")}
                          </LoadingButton>
                        </span>
                      </BootstrapTooltip>
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <LoadingButton
                            loading={jobLoading}
                            onClick={handleCronJob}
                            disabled
                            variant="contained"
                            color="primary"
                            className={classes.jobLoadingButton}
                          >
                            {t("Sync_Now")}
                          </LoadingButton>
                        </span>
                      </BootstrapTooltip>
                    )}

                    <IconButton
                      onClick={(e) => handleClick(e)}
                      variant="outlined"
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      {addPermission && viewPermission ? (
                        locationRequestError ? (
                          <BootstrapTooltip
                            title={`${location?.name} ${t(
                              "Requeste_state_subheading"
                            )}`}
                          >
                            <span>
                              <MenuItem
                                disabled
                                onClick={() => {
                                  closeMenu();
                                  handleClose();
                                  handleOpenAcceptModel();
                                  // handleOpenModal(row?.id);
                                }}
                              >
                                <Typography aria-label="delete" size="large">
                                  {t("Fetch_data_from_Social_accounts")}
                                </Typography>
                              </MenuItem>
                            </span>
                          </BootstrapTooltip>
                        ) : (
                          <MenuItem
                            onClick={() => {
                              closeMenu();
                              handleClose();
                              handleOpenAcceptModel();
                              // handleOpenModal(row?.id);
                            }}
                          >
                            <Typography aria-label="delete" size="large">
                              {t("Fetch_data_from_Social_accounts")}
                            </Typography>
                          </MenuItem>
                        )
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <MenuItem
                              disabled
                              onClick={() => {
                                closeMenu();
                                handleClose();
                                handleOpenAcceptModel();
                                // handleOpenModal(row?.id);
                              }}
                            >
                              <Typography aria-label="delete" size="large">
                                {t("Fetch_data_from_Social_accounts")}
                              </Typography>
                            </MenuItem>
                          </span>
                        </BootstrapTooltip>
                      )}
                    </Menu>

                    {/*   {displayLocationTab===false && (
                    <>
                      {!isMobile &&
                        singleLocation !== null &&
                        allLocations?.length > 0 && (
                          <Divider
                            orientation="vertical"
                            sx={{ marginX: "16px" }}
                            flexItem
                          />
                        )}

                      {singleLocation !== null && allLocations?.length > 0 && (
                        <>
                          <Grid
                            item
                            xs={6}
                            sm={5.8}
                            md={5.8}
                            lg={3.8}
                            sx={{ textAlign: "center" }}
                          >
                            {" "}
                            <Button
                              variant="contained"
                              className={classes.editButtonMap}
                              onClick={() => handleNavigation(location)}
                            >
                              <Typography sx={{ fontWeight: 600 }}>
                                {t("Edit_Location")}
                              </Typography>
                            </Button>
                          </Grid>
                        </>
                      )}
                    </>
                  )} */}
                    {/*  {allLocations?.length > 0 && location !== null && (
                      <>

                        <Divider
                          orientation="vertical"
                          sx={{ marginX: "16px" }}
                          flexItem
                        />

                      </>
                    )} */}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container>
                      <Grid item sm={2} md={2} lg={4} xl={6}>
                        {location !== null && (
                          <>
                            <Typography
                              sx={{
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#495059",
                                marginTop: "8px",
                                marginX: "8px",
                              }}
                            >
                              {`${t("subscriptionDate")} ${
                                location?.subscriptionDate !== null
                                  ? formatDate(location?.subscriptionDate)
                                  : formatDate(location?.createdAt)
                              }`}
                            </Typography>
                          </>
                        )}
                      </Grid>

                      {locationDetailsData !== null && (
                        <Grid
                          item
                          xs={12}
                          sm={10}
                          md={10}
                          lg={8}
                          xl={6}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              lg={6}
                              md={6}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#495059",
                                  marginTop: "8px",
                                  marginX: "8px",
                                }}
                              >
                                {t("Last_Save")}:{" "}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#495059",
                                  marginTop: "8px",
                                  marginX: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {locationDetailsData?.result?.google
                                  ?.updatedAt !== null ? (
                                  `${formatDate(
                                    locationDetailsData?.result?.google
                                      ?.updatedAt
                                  )} ${moment(
                                    locationDetailsData?.result?.google
                                      ?.updatedAt
                                  ).format("HH:mm")}`
                                ) : (
                                  <>{"  " + "   --   "}</>
                                )}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={6}
                              sm={6}
                              lg={6}
                              md={6}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "400",
                                  display: "flex",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#495059",
                                  marginTop: "8px",
                                  marginX: "8px",
                                }}
                              >
                                {t("Last_Synced")}:
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#495059",
                                  marginTop: "8px",
                                  marginX: "4px",
                                  textAlign: "center",
                                }}
                              >
                                {locationDetailsData?.result?.lastSync !==
                                null ? (
                                  `${formatDate(
                                    locationDetailsData?.result?.lastSync
                                  )} ${moment(
                                    locationDetailsData?.result?.lastSync
                                  ).format("HH:mm")}`
                                ) : (
                                  <>{"  " + "   --   "}</>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ paddingY: "6px" }} />
                <>
                  <Tabs
                    value={selectedTab}
                    onChange={handleChangeTab}
                    variant="scrollable"
                  >
                    <Tab label={t("Location_Details")} />

                    <Tab label={t("Channels")} />
                  </Tabs>

                  <Divider />
                </>
              </Card>
            </Grid>
            {locationLoading ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.locationLoader}
                >
                  <Loader />
                </Grid>
              </>
            ) : (
              <>
                {allLocations.length > 0 ? (
                  <>
                    {selectedTab === 0 && (
                      <>
                        {allLocations?.length === 0 ? (
                          <>
                            <Box
                              sx={{ paddingTop: "300px" }}
                              className={classes.locationbox}
                            >
                              <Loader />
                            </Box>
                          </>
                        ) : locationRequestError ? (
                          <Box className={classes.locationbox}>
                            <LocationRequestedState location={location} />

                            {locationAddPermission && locationViewPermission ? (
                              <CommonButton
                                onSubmit={handleAddLocation}
                                label={t("Add_other_location")}
                                icon={<AddIcon sx={{ color: "#FFF" }} />}
                              />
                            ) : (
                              <BootstrapTooltip title={t("authorized_access")}>
                                <span>
                                  <CommonButton
                                    disabled={true}
                                    onSubmit={handleAddLocation}
                                    label={t("Add_other_location")}
                                  />
                                </span>
                              </BootstrapTooltip>
                            )}
                          </Box>
                        ) : displayGoogleError ? (
                          <Box className={classes.locationbox}>
                            <GoogleError
                              text={t("Connect_With_Google")}
                              subtext={t("Connect_Google_Listing")}
                            />

                            {locationAddPermission && locationViewPermission ? (
                              <CommonButton
                                onSubmit={handleConnectWithGoogle}
                                icon={
                                  <img
                                    src={Googleicon}
                                    className={classes.commonButton}
                                    alt={"/google Icon"}
                                  />
                                }
                                label={t("Connect_With_Google")}
                              />
                            ) : (
                              <BootstrapTooltip title={t("authorized_access")}>
                                <span>
                                  <CommonButton
                                    onSubmit={handleConnectWithGoogle}
                                    icon={
                                      <img
                                        src={Googleicon}
                                        className={classes.commonButton}
                                        alt={"/google Icon"}
                                      />
                                    }
                                    disabled={true}
                                    label={t("Connect_With_Google")}
                                  />
                                </span>
                              </BootstrapTooltip>
                            )}
                          </Box>
                        ) : (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div
                              className={classes.locationDetailBoxChartdetails}
                            >
                              <>
                                {loadingLocation ? (
                                  <Box
                                    sx={{ paddingTop: "170px" }}
                                    className={classes.locationbox}
                                  >
                                    <Loader />
                                  </Box>
                                ) : (
                                  <LocationDetails
                                    location={
                                      singleLocation !== null
                                        ? singleLocation
                                        : location
                                    }
                                    changeLoc={changeLoc}
                                    selectedTab={selectedTab}
                                    locationDetailsData={locationDetailsData}
                                    getLocationDetails={getLocationDetails}
                                    googleConnect={googleConnect}
                                    facebookConnect={facebookConnect}
                                    getLocationById={getLocationById}
                                    singleLocation={singleLocation}
                                    setSingleLocationLoading={
                                      setSingleLocationLoading
                                    }
                                    getLocationDetailsListing={
                                      getLocationDetailsListing
                                    }
                                    lodgingCategories={lodgingCategories}
                                    addPermission={addPermission}
                                    editPermission={editPermission}
                                    deletePermission={deletePermission}
                                    viewPermission={viewPermission}
                                    locationEditPermission={
                                      locationEditPermission
                                    }
                                    locationDeletePermission={
                                      locationDeletePermission
                                    }
                                    locationViewPermission={
                                      locationViewPermission
                                    }
                                    locationAddPermission={
                                      locationAddPermission
                                    }
                                    centerData={centerData}
                                    displayMapError={displayMapError}
                                  />
                                )}
                              </>
                            </div>
                          </Grid>
                        )}
                      </>
                    )}

                    {selectedTab === 1 && (
                      <>
                        {channelsLoading ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{
                                padding: "18px",
                                marginTop: "100px",
                                width: "100%",
                              }}
                            >
                              <Loader />
                            </Grid>
                          </>
                        ) : locationRequestError ? (
                          <Box className={classes.locationbox}>
                            <LocationRequestedState location={location} />

                            {locationAddPermission && locationViewPermission ? (
                              <CommonButton
                                onSubmit={handleAddLocation}
                                label={t("Add_other_location")}
                                icon={<AddIcon sx={{ color: "#FFF" }} />}
                              />
                            ) : (
                              <BootstrapTooltip title={t("authorized_access")}>
                                <span>
                                  <CommonButton
                                    disabled={true}
                                    onSubmit={handleAddLocation}
                                    label={t("Add_other_location")}
                                  />
                                </span>
                              </BootstrapTooltip>
                            )}
                          </Box>
                        ) : (
                          <>
                            <ListingChannel
                              location={singleLocation}
                              setLocation={setLocation}
                              channelData={channelData}
                              locationDetailsData={locationDetailsData}
                              channelsLoading={channelsLoading}
                              handleSendUberAllRequest={handleOpenFilterModel}
                              getLocationDetails={getLocationDetails}
                              googleConnect={googleConnect}
                              uberAllLoading={uberAllLoading}
                              handleOpenGoogleModel={handleOpenGoogleModel}
                              addPermission={addPermission}
                              editPermission={editPermission}
                              deletePermission={deletePermission}
                              viewPermission={viewPermission}
                              locationEditPermission={locationEditPermission}
                              locationDeletePermission={
                                locationDeletePermission
                              }
                              locationViewPermission={locationViewPermission}
                              locationAddPermission={locationAddPermission}
                              defaultValueLocation={defaultValueLocation}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Box className={classes.locationbox}>
                      <LocationNotFound
                        text={t("No_Location_Addedd")}
                        subtext={t("No_location_listing_subhead")}
                      />
                      {/*  <CommonButton
                        onSubmit={() => navigate("/user/add-location")}
                        label={t("Add_Location")}
                      /> */}
                    </Box>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </>

      <InternalServerErrorModal
        // open={internalError}
        text={t("server_error_head")}
        subtext1={t("server_error_subhead1")}
        subtext2={t("server_error_subhead2")}
        subtext3={t("server_error_subhead3")}
        handleReloadPage={handleReloadPage}
        src={InternalServerErrorImg}
      />

      {openFilterBulkModel &&
        (selectedTab === 0 ? (
          <FilterModalListings
            title={t("Bulk_Edit")}
            subTitle={t("bulk_update_subheading")}
            allLocations={allLocations}
            openModalFilter={openFilterBulkModel}
            handleCloseModalFilter={handleCloseFilterModel}
            allGroups={allGroups}
            countries={countriesForModel}
            getCountryWiseLocations={getCountryWiseLocations}
            setSelectedLocationsBulkUpdate={setSelectedLocationsBulkUpdate}
            selectedLocationsBulkUpdate={selectedLocationsBulkUpdate}
            locationAddPermission={addPermission}
            locationViewPermission={viewPermission}
            handleOpenBulkEditModel={handleOpenBulkEditModel}
            setProcessedLocations={setProcessedLocations}
          />
        ) : (
          <FilterModal
            title={t("uberall_heading")}
            subTitle={t("bulk_update_subheading")}
            allLocations={allCompanyLocations}
            openModalFilter={openFilterBulkModel}
            handleCloseModalFilter={handleCloseFilterModel}
            allGroups={allGroups}
            countries={countries}
            singleLocation={singleLocation}
            getCountryWiseLocations={getCountryWiseLocations}
            setSelectedLocationsBulkUpdate={setSelectedLocationsBulkUpdate}
            selectedLocationsBulkUpdate={selectedLocationsBulkUpdate}
            locationAddPermission={addPermission}
            locationViewPermission={viewPermission}
            handleSendUberAllRequest={handleSendUberAllRequest}
            bulkLoading={uberAllLoading}
            handleOpenConfirmModel={handleOpenConfirmModel}
            handleCloseConfirmModel={handleCloseConfirmModel}
            openConfirmModel={openConfirmModel}
            setOpenConfirmModel={setOpenConfirmModel}
            setProcessedLocations={setProcessedLocations}
          />
        ))}
      {openEditBulkModel && (
        <ListingBulkUpdateModel
          open={openEditBulkModel}
          handleClose={handleCloseBulkEditModel}
          getLocationDetails={getLocationDetails}
          setSelectedLocationsBulkUpdate={setSelectedLocationsBulkUpdate}
          selectedLocationsBulkUpdate={selectedLocationsBulkUpdate}
          location={location}
          getLocationById={getLocationById}
          handleCloseFilterModel={handleCloseFilterModel}
          processedLocations={processedLocations}
        />
      )}
    </>
  );
}

export default Listings;
